import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { CommonLayout } from './CommonLayout';
import { Button } from '../common/Button';
import { LabelInput } from '../common/LabelInput';

import { signInUser, signOutUser, updateSettings } from '../../../src_old/actions';

import { isEmailValid, isPasswordValid } from '../../utils/regex';
import { media } from '../../utils/media';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 360px;
  min-width: 360px;
  margin: 34px auto 0 auto;

  ${media.PHONE`max-width: unset; min-width: unset;`}
`;

const Actions = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;

const ForgotPassword = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.purple40};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.purple50};
  }
`;

const KeepMeSigned = styled.div``;

const StyledButton = styled(Button)`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
`;

const ErrorText = styled.span`
  font-size: 12px;
  line-height: 16px;
  height: 30px;
  padding-top: 5px;
  color: ${(props) => props.theme.colors.red04};
`;

const TitleText = styled.h1`
  margin-top: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: ${(props) => props.theme.colors.blue02};
`;

/* eslint-disable no-shadow, react/prop-types */
const Login = ({ signInUser, signOutUser, updateSettings, authenticated }) => {
  const { t } = useTranslation(['entrypages', 'common']);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const router = useRouter();

  useEffect(() => {
    signOutUser();
  }, []);

  useEffect(() => {
    const { pathname } = router;
    if (authenticated && pathname === '/login') {
      // This has a bit of a side effect on mount after user submits /login path manually via address bar while being logged in.
      // The SIGN_OUT action gets dispatched but "authenticated" still stays true for a little while.
      // It results in page blinks (temp showing Overview again, i.e. executes push below) and then settling on Login after
      // failing (error 401) certain API calls on Overview page.
      // Ignoring this for now, as log out is not a flow that needs too much attention right now.
      // Might be looked again at during work on https://github.com/spocket-co/supplier-app/pull/451
      router.push('/');
    }
  }, [authenticated]);

  const sendSignInUser = () => {
    setIsLoading(true);

    // TODO - Refactor it to move away from Redux. See issue https://github.com/spocket-co/supplier-app/issues/375
    signInUser({ email, password }, router)
      .then(() => {
        updateSettings('GET');
      })
      .catch(() => {
        setIsLoading(false);
        setErrors({ wrongEmailOrPass: t('login.form.wrong_email_or_pass') });
      });
  };

  const getEmailValidationErrorMessage = () => {
    if (email.trim() === '') {
      return t('login.form.email_validation_required');
    }
    if (!isEmailValid(email)) {
      return t('login.form.email_validation_invalid');
    }
    return null;
  };

  const getPasswordValidationErrorMessage = () => {
    if (password.trim() === '') {
      return t('common:form.password_validation_required');
    }
    if (!isPasswordValid(password)) {
      return t('common:form.password_validation_invalid');
    }
    return null;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isEmailValid(email) && isPasswordValid(password)) {
      sendSignInUser();
    } else {
      setErrors({
        email: getEmailValidationErrorMessage(email),
        password: getPasswordValidationErrorMessage(password)
      });
    }
  };

  const handleBlur = ({ target }) => {
    const fieldName = target.name;

    if (email && fieldName === 'email') {
      setErrors({
        ...errors,
        email: getEmailValidationErrorMessage(email)
      });
    }
    if (password && fieldName === 'password') {
      setErrors({
        ...errors,
        password: getPasswordValidationErrorMessage(password)
      });
    }
  };

  return (
    <CommonLayout imageSrc="assets/login-illustration.png">
      <Form onSubmit={handleSubmit} data-testid="login-form">
        <TitleText>{t('login.title')}</TitleText>
        <LabelInput
          name="email"
          label={t('login.form.email_label')}
          placeholder={t('login.form.email_placeholder')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleBlur}
          errorText={errors.email}
          dataCy="login-text-input"
        />
        <LabelInput
          name="password"
          label={t('login.form.password_label')}
          type="password"
          placeholder={t('login.form.password_placeholder')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={handleBlur}
          errorText={errors.password}
          dataCy="login-password-input"
        />
        <Actions>
          {/* TODO - Add keep me signed in feature. For now, it is an empty component.  */}
          <KeepMeSigned />
          <ForgotPassword href="/reset-password">{t('login.forgot_password')}</ForgotPassword>
        </Actions>
        <ErrorText>{errors.wrongEmailOrPass}</ErrorText>
        <StyledButton
          action="submit"
          disabled={isLoading}
          isLoading={isLoading}
          loadingAnimationSize={25}
          dataCy="login-button">
          {t('login.form.submit_button')}
        </StyledButton>
      </Form>
    </CommonLayout>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signInUser, signOutUser, updateSettings }, dispatch);
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    resetModalIsOpen: state.auth.resetModalIsOpen
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

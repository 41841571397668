import PropTypes from 'prop-types';

export const NonAuthLayout = ({ children }) => {
  return <>{children}</>;
};

NonAuthLayout.propTypes = {
  children: PropTypes.node
};

NonAuthLayout.defaultProps = {
  children: null
};

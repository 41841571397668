import styled from 'styled-components';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Card = styled.div`
  padding: 5px 10px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  font-family: Avenir;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.black20};
`;

// TODO this is not as visible as it should be
const Pointer = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: ${(props) => `6px solid ${props.theme.colors.white}`};
`;

export const Tooltip = ({ trigger, display, onActions, position, className, noPointer }) => {
  return (
    <Popup
      trigger={trigger()}
      position={position}
      on={onActions}
      arrow={false}
      className={className}>
      <ContentWrapper>
        <Card>{display()}</Card>
        {!noPointer && <Pointer />}
      </ContentWrapper>
    </Popup>
  );
};

Tooltip.propTypes = {
  trigger: PropTypes.func.isRequired,
  onActions: PropTypes.arrayOf(PropTypes.string),
  display: PropTypes.func.isRequired,
  position: PropTypes.string,
  className: PropTypes.string,
  noPointer: PropTypes.bool
};

Tooltip.defaultProps = {
  onActions: ['hover', 'focus'],
  position: 'top center',
  className: '',
  noPointer: false
};

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { TooltipIcon } from './TooltipIcon';
import { TextInput } from './TextInput';

const Label = styled.label`
  font-family: Avenir;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.grey80};
  display: flex;
  flex-direction: column;
`;

const StyledTextInput = styled(TextInput)`
  margin-top: 12px;
  font-size: 16px;
`;

const ErrorText = styled.span`
  font-size: 12px;
  line-height: 16px;
  height: 30px;
  padding-top: 5px;
  color: ${(props) => props.theme.colors.red04};
`;

const LabelText = styled.span`
  display: flex;
  align-items: center;
`;

export const LabelInput = ({
  type,
  name,
  label,
  value,
  onBlur,
  onFocus,
  onChange,
  errorText,
  className,
  onKeyPress,
  placeholder,
  tooltipText,
  disabled,
  dataCy,
  maxLength
}) => {
  return (
    <Label className={className}>
      <LabelText>
        <span>{label} </span>
        {tooltipText && (
          <TooltipIcon tooltipText={tooltipText} iconStyle={{ marginLeft: '12px' }} />
        )}
      </LabelText>
      <StyledTextInput
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        value={value}
        type={type}
        name={name}
        disabled={disabled}
        dataCy={dataCy}
        maxLength={maxLength}
      />
      <ErrorText>{errorText}</ErrorText>
    </Label>
  );
};

LabelInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  errorText: PropTypes.string,
  name: PropTypes.string,
  tooltipText: PropTypes.string,
  dataCy: PropTypes.string,
  maxLength: PropTypes.string
};

LabelInput.defaultProps = {
  disabled: false,
  className: '',
  placeholder: '',
  value: '',
  type: 'text',
  errorText: '',
  name: '',
  tooltipText: '',
  dataCy: '',
  maxLength: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onKeyPress: () => {}
};

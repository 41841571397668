import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tooltip } from './Tooltip';

const TooltipTrigger = styled.img`
  cursor: pointer;
  width: 17px;
  height: 17px;
`;

const TooltipText = styled.div`
  color: ${(props) => props.theme.colors.black20};
  font-family: Avenir;
  font-size: 12px;
  line-height: 19px;
  max-width: 250px;
  text-align: left;
`;

export const TooltipIcon = ({ tooltipText, iconStyle, testId, position, noPointer }) => {
  return (
    <Tooltip
      position={position}
      noPointer={noPointer}
      aria-label="tooltip-name"
      display={() => <TooltipText>{tooltipText}</TooltipText>}
      trigger={() => (
        <TooltipTrigger
          style={iconStyle}
          src="/assets/icons/tooltip-question-mark-icon.svg"
          aria-label="tooltip-icon"
          data-testid={testId}
        />
      )}
    />
  );
};

TooltipIcon.propTypes = {
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  iconStyle: PropTypes.object, // object with arbitrary css rules, can't be "shaped".
  testId: PropTypes.string,
  position: PropTypes.string,
  noPointer: PropTypes.bool
};

TooltipIcon.defaultProps = {
  iconStyle: {},
  testId: '',
  position: 'top center',
  noPointer: false
};

import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledInput = styled.input`
  outline: none;
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  border: ${(props) => `1px solid ${props.theme.colors.grey20}`};
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  ::placeholder {
    color: ${(props) => props.theme.colors.grey10};
  }
`;

export const TextInput = ({
  placeholder,
  type,
  className,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  name,
  disabled,
  dataCy,
  maxLength
}) => {
  return (
    <StyledInput
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      value={value}
      type={type}
      name={name}
      disabled={disabled}
      data-cy={dataCy}
      maxLength={maxLength}
    />
  );
};

TextInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  dataCy: PropTypes.string,
  maxLength: PropTypes.string
};

TextInput.defaultProps = {
  disabled: false,
  className: '',
  placeholder: '',
  value: '',
  type: 'text',
  name: '',
  dataCy: '',
  maxLength: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onKeyPress: () => {}
};

// as per https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Basic_validation
const EMAIL_REGEX = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);
const PASSWORD_REGEX = new RegExp('.{6}');

export const isEmailValid = (email = '') => {
  if (typeof email !== 'string') return false;
  return EMAIL_REGEX.test(email.trim());
};

export const isPasswordValid = (password = '') => {
  return PASSWORD_REGEX.test(password);
};

const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// using old regex from the yup validation rules, amend as required
export const isPhoneValid = (phone = '') => {
  return PHONE_REGEX.test(phone);
};

import React from 'react';

import styled from 'styled-components';
import { useTranslation, Trans } from 'next-i18next';
import { media } from '../../utils/media';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  ${media.PHONE`text-align: left;`}
`;

const FooterText = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-bottom: 24px;

  color: ${(props) => props.theme.colors.black15};

  strong {
    color: ${(props) => props.theme.colors.purple40};
  }
`;

const Links = styled.span`
  ${media.TABLET`display: block;`}
`;

const FooterLink = styled.a`
  margin-right: 24px;
  color: ${(props) => props.theme.colors.purple40};
  font-weight: 500;

  ${media.TABLET`display: block; margin: 12px 0;`}
`;

export const Footnote = () => {
  const { t } = useTranslation(['common']);

  const links = [
    {
      url: 'https://www.spocket.co/supplier-privacy-policy',
      title: t('footer.privacy_policy')
    },
    {
      url: 'https://www.spocket.co/supplier-terms-of-service',
      title: t('footer.terms_and_conditions')
    },
    {
      url: 'https://www.spocket.co/supplier-data-processing-addendum',
      title: t('footer.data_processing')
    }
  ];

  return (
    <Wrapper>
      <FooterText>
        <Trans i18nKey="footer.message">
          Learn more about <strong>Spocket’s</strong>
        </Trans>{' '}
        <span role="img" aria-label="Backhand Index Pointing Down">
          👇
        </span>
      </FooterText>

      {links.map((link) => {
        const { url, title } = link;
        return (
          <Links key={title}>
            <FooterLink href={url} target="_blank" rel="noopener noreferrer">
              {title}
            </FooterLink>
          </Links>
        );
      })}
    </Wrapper>
  );
};

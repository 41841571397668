import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { NonAuthLayout } from '../src/components/NonAuthLayout';
import Login from '../src/components/EntryPages/Login';

const LoginPage = () => {
  return (
    <NonAuthLayout>
      <Head>
        <title data-cy="login-title">Login page</title>
      </Head>
      <Login />
    </NonAuthLayout>
  );
};

export default LoginPage;

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'entrypages']))
  }
});

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from '../../utils/media';
import { Footnote } from './Footnote';

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  height: 100vh;
  position: relative;
  font-family: 'Avenir';

  ${media.TABLET`flex-direction: column; height: 100%;`}
`;

const ImageContainer = styled.div`
  height: 100vh;
  width: 100%;

  ${media.TABLET`height: fit-content;`}
`;

const TabletScreenImage = styled.img`
  object-fit: cover;
  object-position: bottom;
  height: 200px;
  width: 100%;
  display: none;

  ${media.TABLET`display: block;`}
`;

const DesktopScreenImage = styled.img`
  object-fit: cover;
  object-position: right;
  height: 100vh;
  width: 100%;

  ${media.TABLET`display: none;`}
`;

const Content = styled.div`
  width: 100%;
  padding: 0 50px;
  padding: 25px;

  ${media.TABLET`width: 100%; padding: 25px;`}
`;

const SpocketLogo = styled.img`
  position: absolute;
  top: 60px;
  left: 60px;
  height: 55px;

  ${media.SMALL_DESKTOP`height: 45px; top: 50px; left: 50px;`}

  ${media.TABLET`left:0; right:0; margin-left:auto; margin-right:auto;`}
`;

export const CommonLayout = ({ imageSrc, children }) => {
  return (
    <Container>
      <SpocketLogo src="assets/spocket-logo-white.svg" alt="Spocket logo" />
      <ImageContainer>
        <TabletScreenImage src="assets/entry-pages-illustration.svg" alt="banner image" />
        <DesktopScreenImage src={imageSrc} alt="hero image" />
      </ImageContainer>

      <Content>
        {children}
        <Footnote />
      </Content>
    </Container>
  );
};

CommonLayout.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
